<template>
	<div class="container">
		<back :prePage="prePage" />
		<div class="operate">
			<el-input placeholder="请输入标题内容" v-model="title" class="input-with-select" clearable @clear="clear">
				<el-button slot="append" icon="el-icon-search" @click.stop="getList"></el-button>
			</el-input>
			<div @click="addMind($route.params.userId)" class="list">
				<el-button icon="el-icon-circle-plus" type="primary">新建思维导图</el-button>
			</div>
		</div>
			<div class="list-box" v-if="list.length != 0" v-cloak>
				<div class="item" v-for="item in list" :key="item.id">
					<div class="item-box" @click="toDetail(item)" @contextmenu.prevent="onContextmenu(item)">
						<div class="img">
							<img style="object-fit: contain;" :src="item.titleImg" alt="">
						</div>
						<p>{{ item.title }}</p>
						<!-- <el-button type="primary" size="mini" round>修改文件</el-button> -->
						<!-- <el-button type="danger" size="mini" round @click.stop="handleDelete(item)">删除文件</el-button> -->
					</div>
				</div>
			</div>
		<el-empty description="暂无数据" v-else></el-empty>
	</div>
</template>

<script>
import {
	updateMind,
	delMind,
	getMindList,
	ceaterNewMind
} from "@/api";
import back from "@/components/back";
export default {
	name: "file",
	components: {
		back
	},
	data() {
		return {
			prePage: "", //上一页路由
			title: '',
			list: []
		};
	},
	//路由钩子
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.prePage = from.fullPath;
		});
	},
	methods: {
		updateMindTitle(id, title) {
			updateMind({
				id: id,
				userId: this.$route.params.userId,
				title: title
			}).then(res => {
				this.$message.success("修改成功")
				this.getList()
			})
		},
		onContextmenu(item) {
			this.$contextmenu({
				items: [{
					label: "重命名",
					divided: true,
					icon: "el-icon-edit-outline",
					onClick: () => {
						this.$prompt('请输入新的名称', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
						}).then(({
							value
						}) => {
							this.updateMindTitle(item.id, value)
						})
					}
				},
				{
					label: "删除",
					icon: "el-icon-delete",
					onClick: () => {
						this.handleDelete(item)
					}
				},
				],
				event,
				//x: event.clientX,
				//y: event.clientY,
				customClass: "custom-class",
				zIndex: 3,
				minWidth: 150
			});
			return false;
		},
		handleDelete(item) {
			// console.log(item);
			this.$confirm('是否确认删除为"' + item.title + '"的数据？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				customClass: 'del-model',
				type: 'warning'
			}).then(() => {
				delMind(item.userId, item.id).then(res => {
					this.getList();
					this.$message({
						message: '文件删除成功!',
						type: 'success'
					})
				})
			})
		},
		toDetail(item) {
			open('http://mind.wantuyun.cn/?userId=' + item.userId + '&id=' + item.id + '&token=' + this.Storage.get(
				"token"))
		},
		addMind() {
			ceaterNewMind({
				userId: this.$route.params.userId
			}).then(res => {
				if (res.code === 200) {
					open('http://mind.wantuyun.cn/?userId=' + this.$route.params.userId + '&id=' + res.data +
						'&token=' + this.Storage.get("token"))
				}
			})
		},
		clear() {
			this.title = ''
			this.getList()
		},
		getList() {
			getMindList({
				userId: this.$route.params.userId,
				title: this.title
			}).then(res => {
				if (res.code === 200) {
					// console.log(res);
					this.list = res.data
				}
			})
		}
	},
	created() {
		this.getList()
		// this.getFilesData(this.$route.params.folderId);
		// this.getTemplateFileList();
	},
};
</script>

<style lang="scss" scoped>
::v-deep .el-input-group__append {
	background-color: #409EFF;
	color: #fff;
}

.container {
	background-color: #EFF2F7;
	padding: 30px;
	min-height: 100vh;

	.operate {
		margin-top: 30px;
		display: flex;
		justify-content: flex-end;

		.input-with-select {
			width: 300px;
			margin-right: 30px;
		}
		.list {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}

	.list-box {
		/* width: 100%; */
		margin-top: 50px;
		padding: 30px;
		display: flex;
		flex-wrap: wrap;

		.item {
			padding: 0 20px 10px;
			width: 20%;
			margin-bottom: 20px;

			.item-box {
				box-shadow: 0 0 10px rgba($color: #000000, $alpha: .1);
				background-color: #fff;
				border-radius: 10px;
				overflow: hidden;
				transition: ease .4s;
			}

			.item-box:hover {
				box-shadow: 2px 5px 15px rgba($color: #000000, $alpha: .2);
				transform: translateY(-12px);
			}

			.img {
				img {
					background-color: #FBFBFB;
					width: 100%;
					height: 200px;
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				}
			}

			p {
				text-align: left;
				padding-left: 18px;
				font-size: 14px;
				line-height: 40px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

		}

		// display: flex;
		// justify-content: flex-end;
		// align-items: center;

	}
}</style>